<script lang="ts">
	import { ValidationMessage } from '@felte/reporter-svelte';
	import Text from '$lib/components/input/text.svelte';
	import { slide } from 'svelte/transition';
	export let name: string;
	export let label: string;
	export let type: string = 'text';
	export let error = false;
</script>

<div class="input-container">
	<span class="text-input">
		<Text label_for={name} {label} {type} {name} {error} />
	</span>
	<ValidationMessage for={name} let:messages>
		{#if messages && messages.length > 0}
			<div class="validation-message" transition:slide={{ duration: 300, axis: 'y' }}>
				<ul aria-live="polite" class="validation-message-ul">
					{#each messages as message}
						<li>{message}</li>
					{/each}
				</ul>
			</div>
		{/if}
	</ValidationMessage>
</div>

<style>
	.text-input {
		position: relative;
		z-index: 2;
	}

	.input-container {
		position: relative;
		width: 100%;
	}

	.validation-message {
		position: relative;
		width: 100%;
		background-color: rgb(24 24 27);
		border-color: rgb(98, 98, 99);
		border-radius: 0 0 0.375rem 0.375rem;
		border-style: solid;
		border-width: 2px;
		border-top: none;
		padding: 0.3rem;
		box-sizing: border-box;
		margin-top: -6px;
		z-index: 0;
	}

	.validation-message-ul {
		margin: 0;
		padding: 0;
		padding-top: 0.25rem;
		list-style: none;
		color: #f07575;
	}

	.validation-message-ul li {
		margin-bottom: 0.25rem;
	}

	.validation-message-ul li:last-child {
		margin-bottom: 0;
	}
</style>
